// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// Producción
export const environment = {
  production: false,
  api: {
    grant_type: 'password',
    apiUrl : 'http://178.128.207.86:8080/',
    clientId: '66',
    secret: 'Zc3FzvqgRuIRw9kpjgvQD5b8nIrNb2AIgSf7qhd7',
    scope: '*'
  }
};

// Desarrollo
// export const environment = {
//   production: false,
//   api: {
//     grant_type: 'password',
//     apiUrl: 'http://127.0.0.1:8000/',
//     clientId: '6',
//     secret: 'XLIeAi5CGroXBAjigSuFt2VUvRBjcJxeeDXGgYBD',
//     scope: '*'
//   }
// };


//sare pruebas
// export const environment = {
//   production: false,
//   api: {
//     grant_type: 'password',
//     apiUrl : 'http://134.122.69.47:8080/',
//     clientId: '6',
//     secret: 'XLIeAi5CGroXBAjigSuFt2VUvRBjcJxeeDXGgYBD',
//     scope: '*'
//   }
// };