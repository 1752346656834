import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { ApiService } from '../api/api.service';
import { HttpHeaders } from '@angular/common/http';
import { ProfileResolver } from '@app/resolvers/profile.resolver';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private profileService: ProfileResolver
    ) { }

  public login(username, password) {
    var formData = new FormData();
    
    formData.append('grand_type', environment.api.grant_type);
    formData.append('client_id', environment.api.clientId);
    formData.append('client_secret', environment.api.secret);
    formData.append('username', username);
    formData.append('password', password);
    formData.append('scope', environment.api.scope);

    const credentials = {
      'grant_type': environment.api.grant_type,
      'client_id': environment.api.clientId,
      'client_secret': environment.api.secret,
      'username': username,
      'password': password,
      'scope': environment.api.scope
    };

    const headers = new HttpHeaders({skipMessage: 'true'});
    
    //console.log(' valor en aut de headers: ', headers);
    //console.log(' valor en aut de credenciales: ', credentials);

    return new Observable(observable => {
      this.apiService.post('oauth/token', credentials, headers).subscribe(
        response => {
          //console.log('valor response: ', response['access_token']);
          this.cookieService.set('token', response['access_token']);
          observable.next(true);
          observable.complete();
        },
        error => {
          console.log(' error en auth: ', error);
          observable.error(error);
        }
      )
    });
  }

  public logout() {
    return new Observable(observable => {
      this.apiService.delete('api/logout')
        .subscribe(() => {
          this.cookieService.deleteAll();
          this.profileService.clearProfile();
          observable.next();
          observable.complete();
        },
        error => {
          observable.error(error);
        });
    })
  }

  forgotPassword(data: any) {
    return this.apiService.post('api/forgot-password', data);
  }

  resetPassword(data: any) {
    return this.apiService.put('api/reset-password', data);
  }

  reiniciarPassword(data: any) {
    return this.apiService.put('api/reiniciarPassword', data);
  }

  primerIngreso(data: any) {
    return this.apiService.put('api/bienvenida', data);
  }

  getMenu(id_usuario) {
    return this.apiService.get(`${'api/menu'}/${id_usuario}`);
  }

  getOpciones(filters?: any) {
    return this.apiService.get('api/opciones', filters);
  }



  public setToken(token) {
    this.cookieService.set('token', token);
  }

  public getToken() {
    return this.cookieService.get('token');
  }

  public deleteToken() {
    this.cookieService.delete('token');
  }

  public isAuthenticated() {
    if (this.getToken())
      return true;
    return false;
  }



}
