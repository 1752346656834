import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { environment } from '@env/environment';

import { CiudadanoService } from '@app/services/ciudadano/ciudadano.service';
import { RegistroService } from '@app/services/registro/registro.service';

import { TabHeadingDirective } from 'ngx-bootstrap/tabs';
import { style } from '@angular/animations';
import { LoaderService } from '@app/services/loader/loader.service';


@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html', 
  styleUrls: ['./registros.component.scss']
})
export class RegistrosComponent implements OnInit {

  filterForm: UntypedFormGroup;
  departamentos = [];
  departamentosnac = [];
  isLoginLoading = false;
  isLoadingMunicipios = false;
  isLoadingMunicipiosnac = false;
  isLoadingCatSex = false;
  isLoadingNacionalidad = false;
  isLoadingCatReg = false;
  isLoadingComunidades = false;
  isLoadPueblos = false;
  municipios;
  municipiosnac;
  uno =1;
  dos = 2;
  pueblo =[];
  comunidades =[];
  sexo = [];
  tipoRegistro =[];
  tipoFormulario =[];
  nacionalidad =[];
  nombre;
  data;
  dataPagination;
  totalItems: number = 0;
  maxSize: number = 5;
  valRadio;
  valRadioadd;
  para;
  paraOld;
  aceptaTerminos: boolean= false;

  constructor(private formBuilder: UntypedFormBuilder,
    private registroService: RegistroService,
    private ciudadanoService: CiudadanoService,
    private router: Router,
    private toastrService: ToastrService,
    public loaderService: LoaderService
  ) { }

  ngOnInit(): void {
      this.initFilterForm();
      this.loadNacionalidad();
      this.loadGeneros()
      this.loadPueblos();
      this.loadComunidades();
      this.loadDepartamentos();
      this.loadDepartamentosnac();

  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      cui: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
      primer_nombre: ['', Validators.required],
      segundo_nombre: [''],
      tercer_nombre: [''],
      primer_apellido: ['', Validators.required],
      segundo_apellido: [''],
      apellido_casada: [''],
      telefono: ['', Validators.required],
      fecha_nacimiento: ['', Validators.required],
      nit: ['', Validators.required],
      correo_electronico: ['', [Validators.required, Validators.email]],
      nasp: [''],
      numero_resolucion_nasp: [''],
      direccion1: ['', Validators.required],
      direccion2: [''],
      id_departamento: ['', Validators.required],
      id_municipio: ['', Validators.required],
      id_departamentonac: [''],
      id_municipionac: [''],      
      id_pueblo: ['', Validators.required],
      id_comunidad_linguistica: ['', Validators.required],
      id_sexo: ['', Validators.required],
      id_nacionalidad: ['', Validators.required],
      direccion_ip: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirma_password: ['', [Validators.required, Validators.minLength(8)]],
      pasaporte: [''],
      correo_casillero: ['', Validators.email],
      direccion: [''],
      casillero: [''],
      tipo_casillero: [''],
      tipo_usuario: 2


    },
    {
      validators: this.mustMatch('password', 'confirma_password')
    });
  }

  radioAdherir(){
    var direc;
    this.valRadioadd = document.querySelector('input[name="add_casillero"]:checked')
    if(this.valRadioadd.value == "si"){
      this.filterForm.get('correo_casillero').setValue(this.filterForm.get('correo_electronico').value);
        this.filterForm.get('direccion').setValue('');
        this.filterForm.get('correo_casillero').enable();
        this.filterForm.get('direccion').disable();
        //document.getElementById('corcas').focus();
    } else if(this.valRadioadd.value == "no") {
      this.filterForm.get('correo_casillero').setValue('');
      this.filterForm.get('correo_casillero').disable();
      this.filterForm.get('direccion').enable();
      //document.getElementById('dirfisica').focus();
    }
  }

  visualizarcui:  number = 0;
  cambiar(e)
  {
    var valor = e.value
    if (valor  != 1){
      this.visualizarcui = 1;
    } else {
          this.visualizarcui = 0;
    }
  }

  loadPueblos() {
    this.isLoadPueblos = true;
    this.registroService.getPueblos()
      .subscribe(response => {
        console.log('pueblos  ', response);
           response.forEach((element) => {
              this.isLoadPueblos = false;
              this.pueblo.push(element);
          });

      });
  }

  aceptoTerminos(input){
    console.log(' valor chek', input.value)
    this.toastrService.warning('chekecado.', 'Aviso');
  }

  loadNacionalidad() {
    this.registroService.getNacionalidad()
      .subscribe(response => {
        console.log('nacionalidad  ', response);
           response.forEach((element) => {
              this.nacionalidad.push(element);
          });

      });
  }

  loadComunidades() {
    this.isLoadingComunidades = true;
    this.registroService.getComunidadesLinguisticas()
      .subscribe(response => {
        console.log('comunidades  ', response);
           response.forEach((element) => {
            this.isLoadingComunidades = false;
              this.comunidades.push(element);
          });

      });
  }

  loadGeneros() {
    this.registroService.getGeneros()
      .subscribe(response => {
        console.log('sexo  ', response);
           response.forEach((element) => {
              this.sexo.push(element);
          });

      });
  }

  loadDepartamentosnac() {
    this.registroService.getDepartamentos()
      .subscribe(response => {
        console.log('departamentos  ', response);
           response.forEach((element) => {
              this.departamentosnac.push(element);
          });

      });
  }

  loadMunicipiosnac(e) {
    //console.log(e)
    //this.isLoadingMunicipiosnac = true;
    this.registroService.getMunicipios({ 'id_departamento': e.value })
      .subscribe(response => {
        //console.log('municipios  ', response);
        //this.isLoadingMunicipiosnac = false;
        this.municipiosnac = response;
      });
  }  

  loadDepartamentos() {
    this.registroService.getDepartamentos()
      .subscribe(response => {
        console.log('departamentos  ', response);
           response.forEach((element) => {
              this.departamentos.push(element);
          });

      });
  }

  loadMunicipios(e) {
    //this.isLoadingMunicipios = true;
    this.registroService.getMunicipios({ 'id_departamento': e.value })
      .subscribe(response => {
        //console.log('municipios  ', response);
        //this.isLoadingMunicipios = false;
        this.municipios = response;
      });
  }

  cancelar(){
      this.router.navigate(['/login']);
  }

  submitted: boolean = false;
  confirmacion: boolean = false;


  registrarUsuario(){
      /*this.submitted = true;
      var longitudcui = ''+this.filterForm.get('cui').value;
      if (longitudcui.length < 13 && this.visualizarcui==0){
        this.toastrService.warning('El DPI debe tener 13 dígitos exáctos.', 'Aviso');
        return;
      }

      var element = <HTMLInputElement> document.getElementById("aceptoTerminos");
      var isChecked = element.checked;
      if (isChecked==false){
        this.toastrService.warning('Debe aceptar términos y condiciones para el envío del formulario.', 'Aviso');
        return;
      }*/

      //crear persona
      if (this.filterForm.invalid)
      {
        this.toastrService.error('Ingrese o seleccione los campos requeridos', 'Error');
        return;
      }
      else 
      {

        

        if (!this.aceptaTerminos) {
          this.toastrService.warning('Debe aceptar términos y condiciones para el envío del formulario.', 'Aviso');
          return;
        }

        this.filterForm.get('fecha_nacimiento').setValue(this.formatDate(this.filterForm.get('fecha_nacimiento').value));

        console.log(this.filterForm.value)

        this.registroService.crearUsuario(this.filterForm.value)
        .subscribe(response => {
          if(response !== null){
            //console.log('crea persona exito:  ', response.message);
            this.toastrService.success(response.message, 'Aviso');
            this.submitted = false;
            this.confirmacion = true;
            this.router.navigate(['/login']);
  
          }
        },
          error => {
            //console.log('crea persona error:  ', error.message);
            this.toastrService.error(error.message, 'Error');
        });
      }
}

get getPasswordControl() { return this.filterForm.controls; }

hasError(input) {
  return this.filterForm.get(input).errors &&
    (
      this.filterForm.get(input).dirty
      || this.filterForm.get(input).touched
      || this.submitted
    )
}

mustMatch(controlName: string, matchingControlName: string) {
  return (filterForm: UntypedFormGroup) => {
    const control = filterForm.get(controlName);
    const matchingControl = filterForm.get(matchingControlName);
    if (matchingControl.errors && !matchingControl.errors.mustMatch)
      return;
    if (control.value !== matchingControl.value)
      matchingControl.setErrors({ mustMatch: true });
    else
      matchingControl.setErrors(null);
  }
}

  onCheckboxChange(e) {
    if (e.checked) {
      this.aceptaTerminos = true
    }
    else {
      this.aceptaTerminos = false
    }

  }

  formatDate(date) {
    let odate = new Date(date);
    const zeroPad = (val) => val.toString().padStart(2, "0");
    let year = odate.getFullYear();
    let month = zeroPad(odate.getMonth() + 1);
    let day = zeroPad(odate.getDate());

    return year + '-' + month + '-' + day;
  };

}
