import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UsuarioService } from '@app/services/usuario/usuario.service';


@Injectable({ providedIn: 'root' })
export class ProfileResolver implements Resolve<any> {

    private profile: any;
    private validacion: any;

    constructor(
        private usuarioService: UsuarioService
    ) { }

    getProfile() {
        return this.profile;
    }
    
    clearProfile() {
        this.profile = null;
    }

    resolve(): Observable<any> | Promise<any> | any {
        return new Observable<boolean>((observable) => {
            this.usuarioService.perfil().subscribe(
                response => {
                    this.profile = response;
                    observable.next(true);
                    observable.complete();
                },
                error => {
                    observable.error(error);
                }
           )
        })
    }
}