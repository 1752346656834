import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth/auth-guard.service';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegistrosComponent } from './views/registros/registros.component';
import { UsuarioComponent } from './views/usuario/usuario.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { InformacionCredencialComponent } from './views/informacioncredencial/informacioncredencial.component';
import { InformacionComponent } from './views/informacion/informacion.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'informacioncredencial/:cod',
    component: InformacionCredencialComponent
  },
  {
    path: 'informacion/:cod',
    component: InformacionComponent
  },
  {//Cambio de nombre para que aparezca dentro se oculta
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'registros',
    component: RegistrosComponent
  }, 
 
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Inicio'
    },
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'usuario',
        loadChildren: () => import('./views/usuario/usuario.module').then(m => m.UsuarioModule)
      },
      {
        path: 'participantes/:id_solicitud',
        loadChildren: () => import('./views/participantes/participantes.module').then(m => m.participantesModule)
      },
      {
        path: 'capacitaciones/:cod/:tipo',
        loadChildren: () => import('./views/capacitaciones/capacitaciones.module').then(m => m.capacitacionesModule)
      },
      {  
        path: 'cambiar-password',
        loadChildren: () => import('./views/cambiar-password/cambiar-password.module').then(m => m.CambiarPasswordModule)
      },
      {
        path: 'pago/:cod',
        loadChildren: () => import('./views/pago/pago.module').then(m => m.PagoModule)
      },
      {
        path: 'pagos/:cod',
        loadChildren: () => import('./views/pagos/pagos.module').then(m => m.PagosModule)
      },
      {
        path: 'subir-documentosesde/:id_solicitud/:numero_solicitud/:id_tipo_solicitud',
        loadChildren: () => import('./views/subir-documentosesde/subir-documentosesde.module').then(m => m.subirdocumentosesdeModule)
      },
      {
        path: 'boletadepago',
        loadChildren: () => import('./views/pago/pago.module').then(m => m.PagoModule)
      },
      {
        path: 'usuario-interno/:tipo',
        loadChildren: () => import('./views/usuario-interno/usuario-interno.module').then(m => m.usuariointernoModule)
      },
      {
        path: 'acreditaciones',
        loadChildren: () => import('./views/acreditaciones/acreditaciones.module').then(m => m.AcreditacionesModule)
      },
      {
        path: 'revisar-documentos/:cod',
        loadChildren: () => import('./views/revisar-documentos/revisar-documentos.module').then(m => m.revisardocumentosdeModule)
      },
      {
        path: 'usuariosinternos',
        loadChildren: () => import('./views/usuariosinternos/usuariosinternos.module').then(m => m.usuariosinternosModule)
      },
      {
        path: 'programaciones',
        loadChildren: () => import('./views/programaciones/programaciones.module').then(m => m.ProgramacionesModule)
      },
      {
        path: 'eventos/:cod',
        loadChildren: () => import('./views/eventos/eventos.module').then(m => m.EventosModule)
      },
      {
        path: 'cargarcalificaciones/:cod',
        loadChildren: () => import('./views/cargarcalificaciones/cargarcalificaciones.module').then(m => m.CargarcalificacionesModule)
      },
      {
        path: 'expediente/:cod',
        loadChildren: () => import('./views/expediente/expediente.module').then(m => m.ExpedienteModule)
      },
      {
        path: 'secretaria',
        loadChildren: () => import('./views/secretaria/secretaria.module').then(m => m.SecretariaModule)
      },
      {
        path: 'busquedasolicitud',
        loadChildren: () => import('./views/busquedasolicitud/busquedasolicitud.module').then(m => m.BusquedaSolicitudModule)
      },
      {
        path: 'revisar-documentos-cert/:cod',
        loadChildren: () => import('./views/revisar-documentos-cert/revisar-documentos-cert.module').then(m => m.revisardocumentosdecertModule)
      },
      {
        path: 'reportes',
        loadChildren: () => import('./views/reportes/reportes.module').then(m => m.ReportesModule)
      },
      {
        path: 'proceso-solicitud/:id_solicitud/:numero_solicitud/:id_tipo_solicitud',
        loadChildren: () => import('./views/solicitudesProceso/solicitudes-proceso.module').then(m => m.SolicitudesProcesoModule)
      },
      



    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
