import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class InformacionCredencial {

  private urls = {
}

  constructor(
    private apiService: ApiService
  ) { }

  getSolicitud(datos) {
    return this.apiService.get(`${'api/consultaexterna'}/${datos}`);
  }

  getFoto(data: any) {
    return this.apiService.get('api/getfotoexterna', data);
  }

}
