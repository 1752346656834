import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private urls = {
    registro: 'api/registro',
    validacion: 'api/usuario/validacion',
    tipoSolicitud: 'api/tiposolicitud',
    centroCapacitacion: 'api/centro-capacitacion',
    ingsolicitud: 'api/guardarSolicitud',
    ingsolicitudcc: 'api/guardarSolicitudcc',    
    departamentos: 'api/departamento',
    municipios: 'api/municipio',   
    pueblos: 'api/pueblo',
    comunidadesLinguisticas: 'api/comunidadLinguistica',   
    catalogo: 'api/catalogo',
    personas: 'api/persona/guardarPersona',
    usuarios: 'api/usuario/crearUsuario',
    agregarRoles: 'api/usuario/agregarRol',
    validando: 'api/validacion',
    solicitud: 'api/solicitud',    
    solicitudes: 'api/solicitudes',     
    solicitudescc: 'api/solicitudescc',         
    mostrarprevios: 'api/mostrarprevios',      
    solicitudDesc: 'api/gettiposolicituddesc',     
    modulos: 'api/getModulos',   
    documentos: 'api/agregardocumento',   
    opcion: 'api/opcion',   
    
}  

  constructor(
    private apiService: ApiService
  ) { }

  perfil() {
    return this.apiService.get('api/perfil');
  }

  getOpciones(filters?: any) {
    return this.apiService.get(this.urls.opcion, filters);
  }

  getTipoSolicitud(filters?: any) {
    return this.apiService.get(this.urls.tipoSolicitud, filters);
  }

  getCatalogoEmpresas() {
    return this.apiService.get('api/empresas');
  }

  getCatalogoCentrosCapa() {
    return this.apiService.get('api/usuario/centroscapacitacion');
  }


  getCentroCapacitacion(filters?: any) {
    return this.apiService.get(this.urls.centroCapacitacion, filters);
  }

  ingresarSolicitud(data: any) {
    return this.apiService.post(this.urls.ingsolicitud, data);
  }

  guardarNotificacion(data: any) {
    return this.apiService.post('api/guardarnuevanotificacion', data);
  }  

  ingresarSolicitudCC(data: any) {
    return this.apiService.post(this.urls.ingsolicitudcc, data);
  }  

  ingresarUsuariosH(data: any) {
    return this.apiService.post('api/usuariosh', data);
  }

  getUsuariosH(filters?: any) {
    return this.apiService.get('api/getUsuariosH', filters);
  }  
  
  getSolicitudes(filters?: any) {
    return this.apiService.get(this.urls.solicitudes, filters);
  }

  getNotificaciones(filters?: any) {
    return this.apiService.get('api/getnotificaciones', filters);
  }  

  busquedaGeneral(filters?: any) {
    return this.apiService.get('api/busquedageneral', filters);
  }

  getEstadoAcreditaciones(filters?: any) {
    return this.apiService.get('api/estado/acreditaciones', filters);
  }

  getBitacora(filters?: any) {
    return this.apiService.get('api/getbitacora', filters);
  }


  getParticipantesSolicitud(id_solicitud) {
    return this.apiService.get(`api/solicitudes/participantes/${id_solicitud}`);
  }

  setDeshabilitarParticipante(id_solicitud) {
    return this.apiService.get(`api/solicitudes/deshabilitarparticipante/${id_solicitud}`);
  }

  setConfirmarParticipantes(id_solicitud) {
    return this.apiService.get(`api/solicitudes/confirmarparticipantes/${id_solicitud}`);
  }

  getSolicitudesParaPagos(filters?: any) {
    return this.apiService.get('api/getsolicitudesparapagos', filters);
  }

  

  getSolicitudesCC(filters?: any) {
    return this.apiService.get(this.urls.solicitudescc, filters);
  } 
  
  getSolicitudesCCPago(filters?: any) {
    return this.apiService.get('api/solicitudesccpago', filters);
  }    

  getCantidadParticipantes(filters?: any) {
    return this.apiService.get('api/cantidadparticipantes', filters);
  }    

  getPrevios(filters?: any) {
    return this.apiService.get(this.urls.mostrarprevios, filters);
  }

  getPreviosCC(filters?: any) {
    return this.apiService.get('api/mostrarprevioscc', filters);
  }

  

  getSolicitud(numerosolicitud) {
    return this.apiService.get(`${'api/gettiposolicituddesc'}/${numerosolicitud}`);
  }  

  getCantidadDocumentosCC(filters?: any) {
    return this.apiService.get('api/cantidaddocumentoscc', filters);
  }    

  devolveranalista(filters?: any){
    return this.apiService.get('api/devolveranalista', filters);
  }

  getSolicitudCC(numerosolicitud) {
    return this.apiService.get(`${'api/gettiposolicituddesccc'}/${numerosolicitud}`);
  }  

  generarBoleta(numerosolicitud) {
    return this.apiService.get(`${'api/generarboleta'}/${numerosolicitud}`);
  }  

  getCatalogoDocumentos(filters?: any) {
    return this.apiService.get('api/catalogodocumento', filters);
  }

  getCatalogoDocumentoscc(filters?: any) {
    return this.apiService.get('api/catalogodocumentocc', filters);
  }

  getExisteDoc(filters?: any){
    return this.apiService.get('api/catalogodocumento2', filters);
  }

  encontrarDisponible(filters?: any){
    return this.apiService.get('api/encontrardisponible', filters);
  }

  setAsignarRenovacion(data: any) {
    return this.apiService.post('api/setasignarrenovacion', data);
  }

  
  devolverdocscc(filters?: any){
    return this.apiService.get('api/devolverdocscc', filters);
  }

  devolverdocsso(filters?: any){
    return this.apiService.get('api/devolverdocsso', filters);
  }  

  

 /// ACA VA, VER COORDINADOR
  
  ingresarDocumentos(data: any) {
    return this.apiService.post('api/agregardocumento', data);
  }

  getCoordinador(filters?: any) {
    return this.apiService.get('api/devolvercoordinador', filters);
  }

  mostrarDocumentoEvaluacionesN(filters?: any) {
    return this.apiService.get('api/mostrardocumentoevaluacionesn', filters);
  }

  getDocumento(data: any) {
    return this.apiService.post('api/mostrarDocumento', data);
  }

  getDocumentoEvaluacion(id_usuario, id_asignacion_evento, tipo) {
    return this.apiService.get(`api/documentos/evaluacion/${id_usuario}/${id_asignacion_evento}/${tipo}`);
  }

  getDocumentosSolicitud(id_solicitud) {
    return this.apiService.get(`api/documentos/solicitud/${id_solicitud}`);
  }

  
  mostrarDocumentoEvaluaciones(filters?: any) {
    return this.apiService.get('api/mostrardocumentoevaluaciones', filters);
  }

  borrarDocumento(data: any) {
    return this.apiService.post('api/borrardocumentos', data);
  }  
 
  estadoDocumento(filters?: any) {
    return this.apiService.get('api/estadoDocumento', filters);
  }

  existeDocumento(filters?: any) {
    return this.apiService.get('api/existeDocumento', filters);
  }  

  aceptarSolicitud(data: any) {
    return this.apiService.post('api/ingresarSolicitud', data);
  }

  setResolucionAnterior(data: any) {
    return this.apiService.post('api/resolucionanterior', data);
  }  

  
  programarEvento(data: any) {
    return this.apiService.post('api/programar/evento', data);
  }

  programarEventoCentrosCapacitacion(data: any) {
    return this.apiService.post('api/programar/evento/centros', data);
  }

  rechazarSolicitud(data: any) {
    return this.apiService.post('api/rechazar/solicitud', data);
  }

  rechazarSolicitudAnalista(data: any) {
    return this.apiService.post('api/rechazar/solicitudanalista', data);
  }

  

  aceptarSolicitudcc(data: any) {
    return this.apiService.post('api/ingresarSolicitudcc', data);
  }

  //nuevo
  agregardocumentossolicitud(data: any) {
    return this.apiService.post('api/agregardocumentos/solicitud', data);
  }

  asignarPorSorteo(data: any) {
    return this.apiService.post('api/asignarporsorteo', data);
  }  

  asignarSolicitudesJuridico(data: any) {
    return this.apiService.post('api/asignar/juridico', data);
  }  

  asignarPsicologoSolicitudes(data: any) {
    return this.apiService.post('api/asignar/psicologo', data);
  }  

  asignarDocumentosDictamen(data: any) {
    return this.apiService.post('api/documentos/dictamen', data);
  }  

  asignarcordinador(data: any) {
    return this.apiService.post('api/asignarcordinador', data);
  }    

  asignarPorSorteocc(data: any) {
    console.log(' datos enviados asignarPorSorteocc: ', data);
    return this.apiService.post('api/asignarporsorteocc', data);
  }    

  getModulos(filters?: any) {
    return this.apiService.get(this.urls.modulos, filters);
  }  

  nostrarNotificaciones(filters?: any){
    return this.apiService.get('api/mostrarnotificaciones', filters);
  }    
  
  nostrarNotificacionescc(filters?: any){
    return this.apiService.get('api/mostrarnotificacionescc', filters);
  }   

  firmaPsicologo(data: any) {
    return this.apiService.post('api/firmapsicologo', data);
  }  

  firmaDirectorSolicitudes(data: any) {
    return this.apiService.post('api/firma/director', data);
  }  

  deshabilitarCredencial(id_solicitud: any) {
    return this.apiService.get(`api/deshabilitar/credencial/${id_solicitud}`);
  }

  getPendientesPago(filters?: any) {
    return this.apiService.get('api/getpendientepagocredencial', filters);
  }  

  informacionBitacora(filters?: any) {
    return this.apiService.get('api/informacionbitacora', filters);
  }  


  getUsuarioByCUI(cui: any) {
    return this.apiService.get(`api/usuario/${cui}`);
  }

  getVerificacionDocumentos(id_solicitud) {
    return this.apiService.get(`api/documentos/verificar/solicitud/${id_solicitud}` );
  }

  getCodigoQR(filters?: any){
    return this.apiService.get('api/getcodigoqrsg', filters);
  }

  getCatalogoReportes()
  {
    return this.apiService.get(`api/catalogo/reportes`);
  }

  getMaximaAsignacion(id_solicitud) {
    return this.apiService.get(`api/asignacion/maxima/${id_solicitud}`);
  }

  setReasignarSolicitud(data: any) {
    return this.apiService.post('api/reasignar/solicitud', data);
  }  

}
