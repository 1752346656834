import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class CiudadanoService {

    private urls = {
        ciudadano: 'api/ciudadano/guardarCiudadano',
        ciudadanoValidacion: 'api/ciudadano-validacion',
        servicio: 'api/servicio',
        reporteAlistados: 'api/reporteAlistados',
        confirmacion: 'api/servicio/confirmacion'
    };

    constructor(
        private apiService: ApiService
    ) { }

    update(data: any) {
        return this.apiService.post(this.urls.ciudadano, data);
    }

    getCiudadanos(filters?: any) {
        return this.apiService.get(this.urls.ciudadano, filters);
    }

    reporteAlistados(filters?: any) {
        console.log('filters', filters);
        console.log('this.urls.reporteAlistados', this.urls.reporteAlistados);
        return this.apiService.get(this.urls.reporteAlistados, filters);
    }

    getCiudadano(cui) {
        return this.apiService.get(`${this.urls.ciudadano}/${cui}`);
    }

    getCiudadanoByToken(token) {
        return this.apiService.get(`${this.urls.ciudadanoValidacion}/${token}`);
    }

    updateServicio(data: any) {
        console.log('updateServicio-data:', data);
        return this.apiService.put(this.urls.servicio, data);
    }

    confirmacion(data: any) {
        return this.apiService.post(this.urls.confirmacion, data);
    }

}
