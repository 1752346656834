import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef, ModalModule} from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { InformacionCredencial } from '@app/services/informacioncredencial/informacioncredencial.service';


@Component({
  selector: 'informacioncredencial.component',
  templateUrl: './informacioncredencial.component.html',
  styleUrls: ['./informacioncredencial.component.scss']
})
export class InformacionCredencialComponent {
  //expedienteForm: FormGroup;
  idSolicitud: string='';
  idUsuario: string='';
  foto:any;
  usuario:any;

  constructor(
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private usuarioService: InformacionCredencial,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    var valor ='';
    valor = this.route.snapshot.paramMap.get('cod');
    var aux = valor.split("*");
    this.idUsuario = aux[0];
    this.idSolicitud = aux[1];
    this.generarSolicitudes(this.idUsuario+'*'+this.idSolicitud);
    this.getFoto(this.idUsuario, this.idSolicitud);
  }

  clasificacion;
  generarSolicitudes(codigo) {
    this.usuarioService.getSolicitud(codigo)
      .subscribe(result =>  {
          this.usuario = result; 
          if(this.usuario[0].sexo == "MASCULINO"){
            if(this.usuario[0].id_tipo_solicitud == 1){
                this.clasificacion = 'DIRECTOR';
            } else if(this.usuario[0].id_tipo_solicitud == 2){
                this.clasificacion = 'INSTRUCTOR';
            }
          } else if(this.usuario[0].sexo == "FEMENINO") {
            if(this.usuario[0].id_tipo_solicitud == 1){
              this.clasificacion = 'DIRECTORA';
            } else if(this.usuario[0].id_tipo_solicitud == 2){
                this.clasificacion = 'INSTRUCTORA';
            }            
          }
          console.log(' datos. ', this.usuario

      )});
     
  }

  getFoto(id_usuario, id_solicitud) {   
		this.usuarioService.getFoto({
		  'id_usuario': id_usuario,
      'id_solicitud': id_solicitud
		})
		.subscribe(result =>  {this.foto = result});
    }

    nuevaFoto;
    castingImages() {    
      for (let i = 0; i < this.foto.length - 1; i++) { 
        this.nuevaFoto[i].picture = this.sanitizer.bypassSecurityTrustResourceUrl(this.foto[i]);
      }
    }


}
