import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root'
})
export class RegistroService {

    private urls = {
        registro: 'api/registro',
        genero: 'api/genero',        
        validacion: 'api/usuario/validacion',
        departamentos: 'api/departamento',
        municipios: 'api/municipio',   
        pueblos: 'api/pueblo',
        comunidadesLinguisticas: 'api/comunidadLinguistica',   
        usuarios: 'api/usuario',
        nacionalidad: 'api/nacionalidad',
        validando: 'api/validacion',
    }

    constructor(
        private apiService: ApiService
    ) { }

    registro(data: any) {
        return this.apiService.post(this.urls.registro, data);
    }

    // validacion(username) {
    //     //return this.apiService.post(this.urls.validacion, data);
    //     console.log(username)
    //     return this.apiService.get(`${'api/validacion'}/${username}`);
    // }

    validacion(data: any) {
        console.log(data)
        return this.apiService.post('api/validacion', data);
    }
    
    validando(data: any) {
        return this.apiService.post(this.urls.validando, data);
    }

    getDepartamentos(filters?: any) {
        return this.apiService.get(this.urls.departamentos, filters);
    }

    getMunicipios(filters?: any) {
        return this.apiService.get(this.urls.municipios, filters);
    }

    getPueblos(filters?: any) {
        return this.apiService.get(this.urls.pueblos, filters);
    }

    getGeneros(filters?: any) {
        return this.apiService.get(this.urls.genero, filters);
    }    
    
    getComunidadesLinguisticas(filters?: any) {
        return this.apiService.get(this.urls.comunidadesLinguisticas, filters);
    }
 
    crearUsuario(data: any) {
        return this.apiService.post(this.urls.usuarios, data);
    }

    getNacionalidad(filters?: any) {
        return this.apiService.get(this.urls.nacionalidad, filters);
    }    
}