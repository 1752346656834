import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ProfileResolver } from '@app/resolvers/profile.resolver';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileResolver
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']);
    }
    return this.profileService.resolve();
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']);
    }

    /*if (next.routeConfig.path == 'dashboard' && (this.profileService.isAdminClient() || this.profileService.isClient() || this.profileService.isSales())) {
      this.router.navigate(['/requests']);
      return true;
    }

    const permissions = next.data.permission;
    let havePermission = false;
    if (permissions) {
      if (this.profileService.isAdmin())
        return true;

      const userPermissions = this.profileService.getPermissions();
      userPermissions.some(userPer => {
        if (userPer.name == permissions) {
          havePermission = true;
          return true;
        }
        return false;
      })

      if (!havePermission)
        this.router.navigate(['/401'])

      return havePermission;
    }*/
    return true;
  }
}
