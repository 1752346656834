import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.api.apiUrl}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(
      `${environment.api.apiUrl}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}, headers: HttpHeaders = new HttpHeaders(), params: HttpParams = new HttpParams(), responseType?: any): Observable<any> {
    return this.http.post(
      `${environment.api.apiUrl}${path}`,
      body,
      {headers, params, responseType: responseType}
    ).pipe(catchError(this.formatErrors));
  }

  delete(path:string, options: object = {}): Observable<any> {
    return this.http.delete(
      `${environment.api.apiUrl}${path}`,
      options
    ).pipe(catchError(this.formatErrors));
  }

  download(path: string) {
    return this.http.get(`${environment.api.apiUrl}${path}`, { responseType: 'blob' });
  }
}
